<app-report-wrapper [totalSteps]="fieldConfig?.length - 1" [step]="step" [showProgressBar]="step > 0" [readonly]="options.formState.readonly" [disableSave]="!formDirty || (step === 1 && getFormBySection(1)?.invalid)" (onExitEvent)="onExit()" (onCompleteEvent)="showCompleteReportDialog()" (onSaveEvent)="onSave()" (onNextSectionClickedEvent)="onNextSectionClicked($event)" (onPreviousSectionClickedEvent)="onPreviousSectionClicked($event)">
  <div class="form-wrapper">
    <form [formGroup]="form">
      <cdk-virtual-scroll-viewport itemSize="10" class="viewport">
        <ng-container *cdkVirtualFor="let section of fieldConfig; let i = index">
          <div class="my-3 px-2" *ngIf="i === step">
            <formly-form [form]="form" [fields]="section.fieldGroup" [model]="model" [options]="options">
              <div class="form-row" *ngFor="let field of section.fieldGroup">
                <formly-field class="form-field" [field]="field"></formly-field>
              </div>
            </formly-form>
          </div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </form>
  </div>
</app-report-wrapper>

import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-primeng-input',
  template: `
    <p-inputNumber 
        [formControl]="formControl"
        [formlyAttributes]="field"
        [mode]="field.props.mode" 
        [showButtons]="field.props.showButtons ?? true"
        [currency]="field.props.currency"
        [prefix]="field.props.prefix"
        [min]="field.props.minLimit ?? 0" 
        [max]="field.props.maxLimit" /> 
  `,
  styleUrls: [
    '../report-content/report-content.component.scss',
    '../regulatory-reports-base.component.scss'
  ],  
})
export class FormlyFieldPrimengNumberInput extends FieldType {
    get formControl(): FormControl {
        return this.field.formControl as FormControl;
    }
}
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { CountriesService } from '../../utils/countries.service';

@Component({
  selector: 'formly-country-field-autocomplete',
  template: `
    <span class="p-fluid">
      <p-autoComplete
          [dropdown]="true"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [forceSelection]="true"
          [multiple]="props?.multiple ?? false"
          [suggestions]="suggestions"
          (completeMethod)="search($event)"
          (onSelect)="onSelect($event)">
          <ng-template let-country pTemplate="selectedItem">
            {{country}}
          </ng-template>
        </p-autoComplete>
  </span>
  `,
  styleUrls: [
    '../report-content/report-content.component.scss',
    '../regulatory-reports-base.component.scss'
  ]})
export class FormlyFieldPrimengCountryAutoComplete extends FieldType {

  public suggestions: any[];
  private maxSelections = 2;

  search(event: any) {
    if (this.props.dataType === 'country') {
      if (!event.query) {
        this.suggestions = CountriesService.getAllCountryNames();
        this.suggestions.sort((a, b) => a.localeCompare(b))
      } else {
        this.suggestions = CountriesService.getJurisdictionSubSet(event.query);
        this.suggestions.sort((a, b) => a.localeCompare(b))
      }
    } else if (this.props.dataType === 'nationality') {
      if (!event.query) {
        this.suggestions = CountriesService.getAllNationalityNames();
        this.suggestions.sort((a, b) => a.localeCompare(b))
      } else {
        this.suggestions = CountriesService.getNationalitySubSet(event.query);
        this.suggestions.sort((a, b) => a.localeCompare(b))
      }
    }
  }

  onSelect(event: any) {
    if (this.props?.multiple && this.formControl.value.length > this.maxSelections) {
      // Remove the last selected item if it exceeds the maximum
      this.formControl.setValue(this.formControl.value.slice(0, this.maxSelections));
    }
  }

  get formControl(): FormControl {
    return this.field.formControl as FormControl;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-primeng-calendar',
  template: `
    <p-calendar 
      appendTo="body"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [showIcon]="true"
      [selectionMode]="field.props.selectionMode || 'single'"
      [maxDate]="field.props.allowFutureDates ? null : today"
      [minDate]="field.props.minDate ?? null"
      dataType="string">
    </p-calendar>
  `,
  styleUrls: [
    '../report-content/report-content.component.scss',
    '../regulatory-reports-base.component.scss'
  ]
})
export class FormlyFieldPrimengCalendar extends FieldType implements OnInit {
  today = new Date();

  constructor() {
    super();
  }

  get formControl(): FormControl {
    return this.field.formControl as FormControl;
  }

  ngOnInit() {    
    const key = this.field?.key?.toString();
    if (this.model && this.model[key]) {
      if (Array.isArray(this.model[key])) {
        this.model[key] = this.model[key].map(date => new Date(date));
      } else {
        this.model[key] = new Date(this.model[key]);
      }
      this.formControl.setValue(this.model[key]);
    }
  }
}
